<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-vector-triangle</v-icon>
      </v-btn>

      <v-toolbar-title>AD-Gruppen</v-toolbar-title>

      <v-spacer />

      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>
    </v-app-bar>
    <v-toolbar flat class="mb-4">
      <v-select
        :items="groupTypes"
        label="Typ"
        hide-details=""
        single-line
        v-model="groupType"
        return-object
        item-text="description"
        @input="changed"
      ></v-select>
    </v-toolbar>

    <v-card class="mt-2">
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :items-per-page="15"
        :search="search"
        :item-class="() => 'clickable'"
        @click:row="(item) => showDetails(item.id)"
      >
      </v-data-table>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    search: '',
    items: [],
    groupType: {},
    groupTypes: [],
    headers: [
      { text: 'id', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Typ', value: 'type.description' },
    ],
  }),
  methods: {
    changed() {
      localStorage.setItem('groupType', this.groupType.id);
      this.getData();
    },
    showDetails(id) {
      this.$router.push({
        name: 'GroupDetails',
        params: { id: id },
      });
    },
    async getData() {
      this.loading = true;
      let id = Number.parseInt(localStorage.getItem('groupType'));
      if (!id) id = 1;
      this.items = await this.apiList({
        resource: 'account/group',
        query: `groupType=${id}`,
      });
      this.groupTypes = await this.apiList({ resource: 'account/grouptype' });
      this.groupTypes.forEach((groupType) => {
        if (groupType.id === id) {
          this.groupType = groupType;
        }
      });
      this.loading = false;
    },
  },
  async created() {
    this.getData();
  },
};
</script>
